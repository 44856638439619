<template>
	<div class="custom-scrollbar" style="width:100%; height:calc(100vh - 265px); overflow-y:scroll">
		<div ref="mailContent" class="mailContent custom-scrollbar single-email">
			<div class="hide-scrollbar min-h-full">
				<div class="dF jSB">
					<div class="dF aC f1">
						<a-avatar shape="circle" :size="40" class="mr-3" style="background-color:#1070CA">{{
							email.from[0].name.toUpperCase() }}</a-avatar>
						<div class="f1">
							<span style="color:#A0AABE">From</span>&nbsp;
							<span style="color:var(--orange)">{{ email.from[0].email }}</span>&nbsp;
							<span style="color:#A0AABE">to</span>&nbsp;
							<span style="color:var(--orange)">{{ email.to[0].email }}</span>
							<div class="dF jSB">
								<h3 class="mt-2">{{ email.subject }}</h3>
								<small style="min-width:max-content" class="dF jE aC"><a-icon type="clock-circle"
										class="mr-2" style="font-size:10px;" />
									{{ moment(email.date).fromNow() }}</small>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-5 min-h-full">
					<EmailFrame v-if="!loading" :key="email.date" :html="email.body"
						:attachments="email.files || []" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import EmailFrame from '@/components/common/EmailFrame'
	import moment from 'moment'
	export default {
		components: { EmailFrame },
		props: ['email'],
		data() {
			return {
				loading: false,
			}
		},
		methods: {
			moment,
		},
	}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
	.single-email {
		width: 100%;
		height: 90%;
		background-color: #FFF;
		border-radius: 4px;
		padding: 40px;
		overflow-y: scroll
	}

	.attachments {
		display: flex;
		align-items: center;
		text-align: center;
	}

	.attachments::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid #EAEEF3;
		margin-left: 1em;
	}


	.custom-scrollbar::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	.custom-scrollbar::-webkit-scrollbar-track {
		background: #F2F2F2;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb {
		background: var(--grey-purple);
		;
		border-radius: 10px;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb:hover {
		background: var(--orange);
	}
</style>
